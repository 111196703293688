// estilo contenedor principal
.nutritional-menu {
    padding: 0px;
}

// estilos cardTabItem
#cardTab {
    min-height: 800px;

    .card-actions {
        width: 100%;
        display: flex;
        justify-content: space-around;
    }

    .card-header {
        padding: 0px !important;
    }

    .btn {
        background-color: #fef5eb;
        padding: 3%;
        margin: 0px !important;
        font-weight: bold;
        font-size: 12px;
    }

    .btn-check:checked+.btn,
    :not(.btn-check)+.btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
        border-color: #f59c39 !important;
    }
}

#select-items {
    .card-header {
        padding: 0px 19.5px !important;
    }

    .card-body {
        padding: 6.5px 32.5px !important;
    }
}

// estilos contenedor de cardItems
.itemsCard-container {
    max-height: 280px;
    overflow-y: auto;
}

// estilos cardItem
.items-card {
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    background-color: #fef5eb;
}

.physical-activity {
    @extend .items-card;
    background-color: #B3DADE;
}

// estilos botonera de acciones
.actions-menu {
    display: flex;
    justify-content: end;
    background-color: #fef5eb;
    border: 1px solid #f59c39;
    padding: 10px;

    .btn {
        margin-left: 15px;
    }

    .easy-edit-wrapper:hover {
        border-radius: 10px;
        padding: 5px 10px;
        background-color: #5c5a5a;
        color: white;
    }
}

// estilos tabla
#menuTab {
    max-height: 640px !important;
    overflow-y: auto;
}

.menu-table {
    border-collapse: collapse;

    th,
    td {
        padding: 8px;
        text-align: center;
        vertical-align: middle;
    }

    .menu-cell {
        //height: fit-content;
        border-left: 1px solid #ccc;
        padding: 0%;
    }

    .menu-cell-content {
        .menu-item {
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            border-radius: 10px;

            .menu-item-name {
                font-size: 0.85rem;
            }
        }

    }

    .btn-sm {
        padding: 0;
    }

    .selected-day {
        background-color: #f59c39 !important;
        color: white;
    }

    .selected-column {
        background-color: #F7E1A7 !important;
    }

    .selected-day-cell {
        background-color: rgba(245, 156, 57, 0.2) !important;
        color: white;
    }

    .selected-column-cell {
        background-color: rgba(247, 225, 167, 0.2) !important;
    }
}

// estilo boton de cerrar
.btnClose:hover {
    background-color: #000000;
    color: white;
    border: 1px solid #ffffff;
}

// estilo accordion
.accordion-item {
    padding: 0px !important;
}

// estilo columna y header fijos
.sticky-column {
    position: sticky;
    left: 0;
    background-color: #f5f5f5 !important;
    z-index: 1;
}

.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f5f5f5 !important;
}

// estilo input cambio de nombre de dia o ingesta
.card-title .easy-edit-wrapper {
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
}

// .easy-edit-button-wrapper {
//     display: none !important;
// }

.card-title .easy-edit-wrapper:hover {
    background-color: #f7f7f7;
    font-style: normal !important;
}

.easy-edit-inline-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.easy-edit-component-wrapper {
    width: 90%;
}

.easy-input {
    width: 100%;
    border-radius: 10px;
    border-style: none;
    padding: 5px 5px 5px 5px;
}

.easy-input:focus {
    outline: none;
    box-shadow: 0 0 0 0.15rem #5DECE3;
}

.easy-input:hover {
    box-shadow: 0 0 0 0.15rem #5DECE3;
}

.easy-edit-button {
    display: none;
}