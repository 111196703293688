.client-chat {
    display: flex;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.client-chat .chat-container {
    height: 600px !important;
    overflow-y: scroll;
    width: 100%;
}

.client-chat .chat-container::-webkit-scrollbar {
    width: 10px;
}

.client-chat .chat-container::-webkit-scrollbar-track {
    background-color: transparent;
}

.client-chat .chat-container::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--bs-secondary);
    opacity: .5;
    border-radius: 15px;
}

.client-chat .non-resize {
    resize: none;
}

.client-chat .chat-avatar {
    border-radius: 50%;
    overflow: hidden;
}

.client-chat .chat-avatar img {
    object-fit: cover;
}

.chat-message-reply {
    background-color: #eaf8f7 !important;
}