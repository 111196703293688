//
// Custom
//

@use 'sass:map';

.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.sticky-top-size {
	top: map.get($header, height) + map.get($subheader, height) + map.get($page, padding-y);
}

.border-transparent {
	--#{$prefix}border-opacity: 0;
}

.aside .navigation-text, .aside .navigation-link {
	color: white !important;
}

.footer {
	z-index: 0;
	align-self: end;
}

.avatar-contain{
	object-fit: contain !important;
}

.chat-message-reply {
	background-color: var(--bs-primary);
}

.order-rations {
	border-radius: 5px;
	color: white;
}

.color-box {
	border-radius: 5px;
	height: 20px;
	width: 20px;
}

.delete-item {
	display: none !important;
}

.menu-item:hover .delete-item {
	cursor: pointer;
	display: block !important;
}

.min-w-180 {
	min-width: 180px !important;
}

.min-w-150 {
	min-width: 150px !important;
}

.max-w-150 {
	max-width: 150px !important;
}

.fs-10px {
	font-size: 12px !important;
}

.max-h-975 {
	max-height: 975px !important;
}