@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';

// Utilities
@import 'node_modules/bootstrap/scss/utilities/api';
@import 'react-big-calendar/lib/addons/dragAndDrop/styles';
@import 'custom/index';


/* --------------- Estilos generales --------------- */
.bg-primary-light {
    background-color: #eaf8f7 !important;
}

.bg-secondary-light {
    background-color: #f5d0a9 !important;
}

.bg-third {
    background-color: #3c3c3b !important;
}

.bg-third-light {
    background-color: #4a4a4a !important;
}

.border-secondary {
    border-color: #f5d0a9 !important;
}

// estilo aside
.modern-design:hover {
    background-color: rgba(48, 183, 175, 2) !important;
}

// estilo para el label
.labelStyle {
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #6c757d;
}

#react-select-3-listbox {
    z-index: 9999 !important;
}

.css-13cymwt-control {
    border-radius: 5px;
}

.btnCloseIcon:hover {
    color: red !important;
    font-size: 1.5rem;
}

// acordeon
.accordion-button {
    border-radius: 10px !important;
    background-color: #fef5eb;
}

.accordion-button:not(.collapsed) {
    background-color: #fef5eb;
    box-shadow: 0 0 0 2px #f5d0a9;
    color: black;
}

.accordion-button:hover {
    box-shadow: 0 0 0 2px #f5d0a9;
}

/* ------------------------------------------------- */

// boton crear alimento
.btnFixed {
    position: fixed;
    bottom: 10%;
    right: 3%;
    z-index: 9999;
}

.user-name {
    color: #fef5eb;
}

.stickyLeft {
    position: sticky;
    top: 0;
    left: -100;
    z-index: 9999;
}

// boton cierre etiqueta en patientProfile
.btn_close_tag {
    background-color: #f5d0a9;
    color: #f59c39 !important;
    opacity: 1;
}

.btn_close_tag:hover {
    background-color: #f59c39;
    color: white !important;
    opacity: 1;
}

// estilo iconos
.icon-large {
    font-size: 1.7rem;
    cursor: pointer;
}

.icon-large:hover {
    color: #414141 !important;
}

.icon-medium {
    font-size: 1.5rem;
    cursor: pointer;
}

.icon-medium:hover {
    color: #414141 !important;
}

.icon-small {
    font-size: 1.3rem;
    cursor: pointer;
}

.icon-small:hover {
    color: #414141 !important;
}

// paginacion
/* .paginatiom-select .css-13cymwt-control {
    border-radius: 10px !important;
    background-color: #fef5eb;
} */

// estilo check de alérgenos
.checkboxList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.checkbox-apple {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    height: 25px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-apple label {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
    border-radius: 50px;
    background: linear-gradient(to bottom, #cacaca, #e6e6e6);
    cursor: pointer;
    transition: all 0.3s ease;
}

.checkbox-apple label:after {
    content: '';
    position: absolute;
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;
}

.checkbox-apple input[type="checkbox"]:checked+label {
    background: linear-gradient(to top, #f5d0a9, #f59c39);
}

.checkbox-apple input[type="checkbox"]:checked+label:after {
    transform: translateX(25px);
}

.checkbox-apple label:hover {
    background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
}

.checkbox-apple label:hover:after {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.yep {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 25px;
}

/* --------------- Estilos del chat --------------- */

.chat-avatar {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.wallpaper {
    //background-image: url('../../public/tramaChat4.jpg');
    background-repeat: repeat;
    background-size: width 25%;
    background-position: center;
    position: absolute;
    z-index: 10;
    opacity: 0.4;
}

/* --------------- Estilos de formularios --------------- */

.formsPage {
    padding: 0px;
}

.formsPage .card-header {
    padding: 0px 0px 0px 0px;
}

.formsPage .btn {
    background-color: #FDF5EB;
    border-color: #FDF5EB !important;
}

.formsPage .card-body {
    padding: 0px;
}

.formTypes .btn {
    background-color: #EAF8F7;
    border-color: #EAF8F7;
}

.cardTabForms .card {
    box-shadow: none;
}

.cardTabForms .card-header.card-header-border-1 {
    border-bottom-width: 0;
    padding-bottom: 20px;
}

.cardTabForms .custom-table {
    padding: 0px !important;
}

.btnAddForms {
    background-color: #f59c39 !important;
    border-color: #f59c39 !important;
}

.btnAddForms2 {
    background-color: #eaf8f7 !important;
    border-color: #eaf8f7 !important;
}

.btnNoBg {
    background-color: transparent !important;
    border-color: transparent !important;
}

/* --------------- Estilos checkbox switch --------------- */
.required-check .form-switch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 0px;
}

/* --------------- Estilos de las notas del paciente --------------- */
.note-card {
    border-radius: 15px;
    margin-bottom: 20px;
}

.note-card .card-body {
    padding-bottom: 0px;
}

.note-card .card-footer {
    padding: 0 19.5px 0 19.5px;
}

.note-card .easy-edit-wrapper {
    padding: 0px;
}

.note-card .textarea-inline .easy-edit-wrapper {
    min-height: 75px;
}

.note-card .easy-input {
    border-radius: 15px;
    padding: 10px;
}

.note-card .easy-edit-inline-wrapper {
    justify-content: start !important;
}

.note-card .easy-edit-button-wrapper {
    margin-left: 15px;
}

/* --------------- Estilos del input de telefono --------------- */
.PhoneInputInput {
    display: block;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: #323232;
    background-color: #F8F9FA;
    background-clip: padding-box;
    border: 1px solid #F8F9FA;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 1rem;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;

    @include dark-mode {
        background-color: $dark-input-bg;
        color: $dark-input-color;

        &:not([class*='valid']) {
            border-color: $dark-input-border-color;
        }
    }

    &:disabled,
    &[readonly] {
        @include dark-mode {
            background-color: $dark-input-disabled-bg;
        }
    }
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)
.PhoneInputInput-plaintext {
    @include dark-mode {
        color: $dark-input-plaintext-color;
    }

    &:focus {
        outline: none;
    }
}

.PhoneInputInput[type='file']::-webkit-file-upload-button {
    @include dark-mode {
        background-color: $dark-input-file-upload-button-bg;
    }

    background-color: $input-file-upload-button-bg;
}

@include dark-mode(true) {

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        box-shadow: 0 0 0 100rem $dark-input-autofill-bg inset;
        -webkit-text-fill-color: $dark-input-autofill-color;
    }
}


/* --------------- Estilos de organization profile --------------- */
.cardLabels .card-header .btn {
    background-color: #FDF5EB;
    border-color: #FDF5EB;
}

.cardLabels .card-body {
    padding: 0px 19.5px;
}


/******************* Estilos del editor de HTML *******************/
.rdw-editor-main {
    border: 1px solid lightgray;
    min-height: 200px;
}

/******************* Estilos del placeholder *******************/
.custom-placeholder::placeholder {
    font-size: 0.8rem;
}

/******************* Estilos del aside *******************/
#aside-menu .active {
    background-color: transparent !important;
    color: black !important;
}

#aside-menu li:hover {
    background-color: transparent !important;
}